/* .flightresultcontainer {
    padding: 0 5px!important;
} */
.roundflightdetailscard {
    padding: 10px 10px 0px;
    background: #fff;
    border-radius: 10px;
    margin: 15px 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
}
.activerndbg {
    border: 1px solid #e81d2a;
}
.flight_details_oneway, .flight_details_round {
    position: relative;
}
.flight_details_oneway::after, .flight_details_round::after {
    content: "";
    width: 2px;
    padding: 1px;
    height: 53px;
    background-color: #fff;
    position: absolute;
    top: -2px;
    right: 0;
}
.pricetotal {
    font-weight: 500;
    margin-right: 8px;
    color: #fff;
    letter-spacing: .3px;
}
.flitercolumstick-right{
    padding-left: 7.5px!important;
}
.tltrndheader {
    color: #e81d2a;
    font-size: 12px;
    font-weight: 500;
}
.tltrndleft {
    font-size: 12px;
    font-weight: 500;
    color: #e81d2a;
    flex-basis: 50%;
    text-align: left;
    text-transform: capitalize;
}

.tltrndheader.leftalign {
    margin-left: 13%;
}

.tltrndcenter {
    font-size: 12px;
    font-weight: 500;
    color: #e81d2a;
    flex-basis: 50%;
    text-align: center;
    text-transform: capitalize;
    margin: 0 7px;
}

.tltrndright {
    font-size: 12px;
    font-weight: 500;
    color: #e81d2a;
    flex-basis: 50%;
    text-align: right;
    text-transform: capitalize;
    z-index: 999;
}
.flexset-rnd {
    display: flex;
    align-items: baseline;
    width: 100%;
}
.flexset-rnd-inside {
    display: flex;
    align-items: baseline;
    width: 100%;
    padding-left: 0px;
}
.viewroundresultbooking {
    padding: 6px 0px;
}

.roundflightimg {
    display: flex;
    align-items: center;
}
.roundmini-tag {
    width: 100%;
    margin-left: 7px;
}
.rndflightname {
    font-size: 10px;
    font-weight: 600;
}
.rndflightnumber {
    font-size: 12px;
    font-weight: 500;
}
.roundviewbtndetails {
    margin-top: 18px;
}
.roundbtnview {
    font-size: 9px!important;
    width: 100%;
    padding: 8px 0px!important;
}
.minitxt {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #dc3545;
}
.defineroundtripwidth {
    width: 100%;
    margin: 0;
    text-align: center;
}
.roundarrowbox {
    width: 150px;
    margin: 3px;
    text-align: center;
}

.define-stop {
    font-size: 11px;
    font-weight: 500;
    color: #000;
    padding-bottom: 8px;
}
.define-timecalc {
    font-size: 11px;
    font-weight: 500;
    padding-top: 8px;
}
 .roundarrow-img {
    position: relative;
}
.roundarrow-img img {
    position: absolute;
    top: -3px;
    width: 42px;
    left: 10px;
}
.roundimgminifly img {
    width: 31px;
}
.roundradioBox {
    width: 100%;
    margin: 0;
    padding: 0;
}
.round-radio-fieldwith-iconlist {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}
.rnditemsradio {
    margin: 0!important;
}
.rnditemsradio span {
    padding: 0px!important;
    padding-right: 3px!important;
}
label.rnditemsradio span {
    font-size: 11px;
    font-weight: 600;
    margin-left: 11px;
}
label.rnditemsradio span:first-child {
    font-size: 11px;
    font-weight: 600;
    position: absolute;
    top: 2px;
    left: -13px;
}
.roundflex-servicesbox {
    width: 100%;
    display: flex;
}
.zerogap {
    padding: 0!important;
}
ul.servicesBoxs-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ul.roundservicesBox {
    padding: 0;
    list-style-type: none;
    width: 100%;
    margin: 0;
}
li.servicesBoxs-li {
    padding: 5px;
}
.round-highlight-price {
    font-size: 17px;
    font-weight: 600;
}
ul.roundlisticon {
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
    margin-left: 10px;
}
li.round-listitemsicon img {
    width: 12px;
    margin: 2px;
    cursor: pointer;
}
button.btnBook {
    font-size: 14px;
    width: 100%;
    background: #0d9310!important;
    color: #fff;
    margin: 5px 0;
}

button.btnBookOutline {
    font-size: 14px;
    width: 100%;
    background: #fff !important;
    color: #0d9310 !important;
    margin: 5px 0;
    border: 1px solid #0d9310;
    padding: 2px !important;
    box-shadow: none !important;
}

.flighttabsdetails{
    min-height: 25px !important;
}
.flighttabsdetails button{
    font-size: 10px;
    min-height: 20px;
}
button.roundflighttabsdetails-link {
    padding: 0 10px!important;
    font-size: 11px;
    text-align: left;
    min-width: max-content;
    min-height: 25px;
    text-transform: capitalize;
}
.roundtabsBox {
    margin: 10px 0px;
    background: #F5F4F4;
    position: relative;
    border-radius: 7px;
}
.closebox {
    position: absolute;
    top: -10px;
    right: -9px;
    cursor: pointer;
}
.bgflightdetailscardroundway {
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
}
span.roundfromname {
    font-size: 12px;
    font-weight: 500;
}
span.roundtoname {
    font-size: 12px;
    font-weight: 500;
}
span.rounddateformto {
    font-size: 12px;
    font-weight: 400;
    color: #707070;
}
.roundto-fromtlt span {
    padding: 0 5px;
}
span.roundicoarrowfromto img {
    width: 35px;
    opacity: .8;
}
.roundtltdate {
    font-size: 11px;
    font-weight: 600;
}
.roundflightairportaddress p {
    font-size: 9px;
    color: #999;
}
.roundarrowtabtxt {
    text-align: center;
    font-size: 10px;
}
.roundarrowtabinsidebox {
    position: relative;
}
.roundarrowtabinsidebox img {
    position: absolute;
    top: 0;
    width: 100px;
}
.roundarrowtabinsidebox img {
    position: absolute;
    top: 3px;
    width: 45px;
    opacity: .7;
    right: 11px;
}
.roundflightairportaddress {
    padding-left: 0;
}
.roundto-fromtlt {
    margin-bottom: 6px;
}
.roundtabpanelsflightpanel>div {
    padding: 10px;
}
.roundtime-calc {
    font-size: 11px;
    font-weight: 700;
}
.roundtype {
    font-size: 10px;
    font-weight: 400;
    color: #999;
}
.roundseathighighted {
    font-size: 10px;
    font-weight: 400;
    color: #999;
}
span.roundseathightlighted {
    color: #dc3545;
    padding-left: 5px;
}

.roundtltBaggage {
    font-size: 11px;
    font-weight: 500;
    color: #656363;
    text-transform: uppercase;
}
.roundtltBaggage-desc {
    font-size: 12px;
    color: #6b6868;
    width: max-content;
}
.roundheaderRow {
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
}
.roundtltfaredetails {
    font-size: 13px;
    font-weight: 500;
    color: #585656;
}
span.roundnote-qt {
    color: #9a9a9a;
    font-size: 13px;
}
.roundtotalRow {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    }
.roundtlt-total {
    font-size: 14px;
    font-weight: 500;
}
.bookingflightroundtrip {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to right, #bb9349, #e81d2a );
    padding: 15px 15px;
    z-index: 9;
    left: 0;
    }
/******* bottom booking details****/
.imgfooterbooking {
    display: flex;
    align-items: center;
}
.footerbookingmini-tag {
    width: 100%;
    margin-left: 7px;
    color: #fff;
}
.footerbookingimgminifly img {
    width: 31px;
}
.footerbookingflightname {
    font-size: 12px;
    font-weight: 600;
}
.footerbookingflightnumber {
    font-size: 12px;
    font-weight: 500;
}
.flexset-footerbooking-inside {
    display: flex;
    width: 100%;
}
.definefooterroundtripwidth {
    margin: 0;
    flex-basis: 30%;
}

.footerflightmininame {
    font-size: 10px;
    font-weight: 600;
    color: #fff;
}
.footerflightminitime {
    font-size: 11px;
    font-weight: 600;
    color: #fff;
}
.footerflightminidata {
    font-size: 10px;
    font-weight: 600;
    color: #fff;
}
/* .footerroundarrowbox {
    width: 240px;
    margin: 3px;
    text-align: center;
} */
.footerdefine-stop {
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    padding-bottom: 8px;
}
.footerroundarrowbox {
    flex-basis: 40%;
}
.footerdefineroundtripwidth {
    flex-basis: 30%;
}
.footerroundarrow-img {
    position: relative;
}
.footerroundarrow-img img {
    position: absolute;
    top: -3px;
    width: 45px;
    left: -1px;
}
.footerdefine-timecalc {
    font-size: 11px;
    font-weight: 500;
    padding-top: 8px;
    color: #fff;
}
.flight_details_btn {
    /* display: flex; */
}
.price {
    color: #fff;
}
.footerflightdetailgap {
    padding: 0!important;
    align-self: center;
}
.btn-booking-roundtrip {
    min-width: 160px;
    margin-left: 25px!important;
    background-color: #0d9310 !important;
    color: #fff !important;
}
.scrollingdiv {
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    height: 1120px;
    padding-right: 10px;
}
.scrollingdiv::-webkit-scrollbar {
    width: 10px;
}
.scrollingdiv::-webkit-scrollbar-track {
    background-color: transparent;
    width: 15px;
}
.scrollingdiv::-webkit-scrollbar-thumb {
    background-color:#9e9e9e47;
    border-radius: 9px;
    width: 8px;
}
.shownetrndway {
    font-size: 13px;
    font-weight: 500;
    margin-left: 19%;
    margin-top: -5px;
    cursor: pointer;
}

.tripheading{
    padding: 4px 10px;
    background: #e81d2a;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
}
.rtnbox {
    background: #e81d2a08;
    margin-top: 16px;
}
.separatebox {
    padding: 10px;
}
button.mailbtn {
    font-size: 12px;
    padding: 2px 10px !important;
    font-weight: 500;
    text-transform: capitalize;
    background: #0d9310 !important;
}

button.selectbtnOutline {
    font-size: 11px !important;
    padding: 1px 7px !important;
    font-weight: 500;
    text-transform: capitalize;
    color: #0d9310 !important;
    border: 1px solid #0d9310;
    background: #fff !important;
    box-shadow: none !important;
}

.flagflt {
    text-align: left;
    /* margin-left: -21px; */
}

.layover_rd {
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 25px;
    color: red;
    background: #fff;
    top: -13px;
    left: 30%;
    border-radius: 7px;
}
.arrowtabinfo {
    position:relative;
   }
   .arrowtabinfo img {
    position: absolute;
    top: 4px;
    left: 45px;
}
.define-timeinfo {
    font-size: 11px;
    font-weight: 500;
    padding-top: 18px;
    text-align: center;
}
.viewroundresultbooking.addonbackground_round {
    background: #eee;
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    display: flex;
}
.MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input {
    padding: 0;
    font-size: 13px;
}

.roundradioBox{
    margin-top: 10px;
}

.shownetrd{
    padding: 0px 12px;
}

.arrow-img-round .arrow-line {
    border-bottom: 1px dotted #6c757d;
    display: block;
    height: 1px;
    width: 100%;
    margin: auto;
}

.arrow-img-round img {
    position: absolute;
    top: 21px;
    width: 14px;
    left: 56%;
    background: #fff;
}

.selectsortby select{
    font-size: 12px !important;
    padding: 0px !important;
    min-width: max-content;
}

.rdttopinfobar .setbtnprevnext {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 0px ;
}
.rdttopinfobar{
    margin: 10px 0px;
}
.rndservicesBoxs .shownetrd {
    padding: 0px ;
}
.share-cbx-round .MuiCheckbox-root {
    padding: 0px !important;
}

.rdttopinfobar .check-whatsapp {
    width: auto !important;
    margin: 0px;
}
.info-label-r{
    color: #fff;
    position: relative;
    font-size: 9.5px;
    font-weight: 500;
    padding: 2px 10px;
    margin-left: -11px;
    border-radius: 2px;
}

.info-label-box{
    margin-top: -18px;
}

.shortdistance-info-bg{
    background: #7abb4b;
}
.dealavailable-info-bg{
    background: orange;
}

.longdistance-info-bg{
    background: #f26a53;
}
.roundtabsBox .MuiTabs-fixed {
    overflow: auto !important;
}

.roundtabsBox .closebox {
    position: absolute;
    top: -10px;
    right: -5px;
    cursor: pointer;
}

.no-flight-roundtrip-p{
    padding: 10px 20px;
    background: #fff;
    border-radius: 4px;
}
