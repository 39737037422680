
.footeraddress {
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    flex-basis: 27%;
    height: auto !important;
    color: #444;
    padding-bottom: 20px;
}
.footer {
    background: #fff;
    /* padding-top: 20px; */
    box-shadow: 0 1px 3px rgba(0,0,0,.15);

}
.footerimg img {
    width: 60%;
}
.footeraddress p, .footeraddress p a {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
    color: #000;
    text-decoration: none;
}
.footer-bottom{
    padding: 10px;
    background: #dd2632;
}
.footer-bottom p{
    color: #fff;
    font-size: 14px;
}
.copyright {
    font-size: 16px;
    font-weight: 500;
    color: #545454;
    width: 100%;
}
.socialicons {
    width: 100%;
    display: flex;
    border: none !important;
}
.linklist p {
    font-size: 14px;
    padding-right: 35px;
    text-align: justify;
}
.linklistul {
    flex-basis: 25%;
    height: auto !important;
}
.linklistul ul {
    list-style-type: none;
    font-size: 13px;
}
.linklistul ul li {
    line-height: 2;
}
.socialicons a svg {
    margin-right: 10px;
    /* margin-top: 10px; */
    color: #e71d2a;
    background: #fff;
    border: 1px solid #e71d2a;
    height: 40px;
    width: 40px;
    padding: 8px;
    border-radius: 50%;
}
.footerimg {
    display: block;
    margin: auto;
}
.footerlogowrp{
    margin: auto;
}
.linklist ul li {
    line-height: 2;
}

.flexboxlink {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 3px solid #1675b8;
}
.listlinkitemsbox {
    display: flex;
    max-width: 100% !important;
}
.linklist {
    margin-top: 35px;
}
.whatsappchat:hover{
    color: #545454 !important;
}
.whatsappchat svg{
    color: green !important;
    font-size: 20px !important;
}
.footerPhoneNo svg{ 
    color: #1675b8;
    font-size: 20px !important;
}
