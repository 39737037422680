
.MuiTimelineItem-root{
    /* min-height: 100px !important; */
}
.MuiTimelineContent-root {
    text-align: right;
    margin: auto !important;
}
.MuiTimelineItem-root:before {
    content: none !important;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 6px 16px;
}

.timeline .MuiTypography-root{
    font-size: 18px;
}

.timeline p{
    font-size: 13px;
}

p.timeline-date{
    font-size: 14px;
    color: #a13d36;
}
p.timeline-date span{
    width: 10%;
}

.timeline .MuiTypography-h6 span{
    font-size: 14px;
}

.tranfers-dot{
    margin-left: 13.6px;
    min-height: 20px !important;
}

.tranfers-dot .MuiTypography-root{
    font-size: 13px;
}