.betweenspace {
    width: 100%;
}
.flexset-skleton{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
}
.btnsktn {
    margin-top: 10px;
}
.flightsearchskeletonview {
    padding: 9px;
    margin-bottom: 6px;
    background: #fff;
    border-radius: 8px;
}
.headingskltn {
    padding: 2px 12px;
}
.pricelistsktn {
    margin-bottom: 8px;
}
.roundtripskeleton {
    display: flex;
}
.fltsrchrnddtripskeleton {
    padding: 9px;
    margin-bottom: 6px;
    background: #fff;
    flex-basis: 50%;
    margin: 3px;
    border-radius: 8px;
}