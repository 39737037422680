/* .zerogutter {
    padding: 0;
} */
.tltreviewheader {
    font-size: 13px;
    font-weight: 500;
    color: #e81d2a;
    text-transform: capitalize;
}
.flexset {
    display: flex;
    align-items: center;
    width: 100%;
}
.tltreviewleft {
    font-size: 13px;
    font-weight: 500;
    color: #e81d2a;
    flex-basis: 50%;
    text-align: left;
    text-transform: capitalize;
}

.tltreviewheader.leftalign {
    margin-left: -4%;
}

.tltreviewheader.netpricealign {
    margin-left: 12%;
}

.tltreviewcenter {
    font-size: 13px;
    font-weight: 500;
    color: #e81d2a;
    flex-basis: 50%;
    text-align: center;
    text-transform: capitalize;
}
.tltreviewright {
    font-size: 13px;
    font-weight: 500;
    color: #e81d2a;
    flex-basis: 50%;
    text-align: right;
    text-transform: capitalize;
}
.viewresultbooking {
    padding: 10px 8px 10px;
}
.viewresultbooking.sortby-des {
    padding: 8px !important;
}
.listiconbox{
    text-align: right;
}
.flightimg {
    display: flex;
    align-items: center;
}
.mini-tag {
    width: 100%;
    margin-left: 7px;
}
.flightname {
    font-size: 12px !important;
    font-weight: 600;
    white-space: nowrap;
    width: 70px;
    overflow: hidden ! important;
    text-overflow: ellipsis;
    cursor:auto;
}

.flightname:hover {
    font-size: 12px;
    position: relative;
    font-weight: 600;
    white-space: nowrap;
    width: auto;
    background: #fff;
    overflow: auto;
    z-index: 9999;
    text-overflow: inherit ;
    padding-right:  20px;

}
.flightnumber {
    font-size: 12px;
    font-weight: 500;
}
.viewbtndetails {
    margin-top: 27px;
}
.minitxt {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #dc3545;
}
.definewidth {
    margin: 3px;
}
.arrowbox {
    width: 150px;
    margin: 3px;
    text-align: center;
}

.define-stop {
    font-size: 11px;
    font-weight: 500;
    color: #000;
    padding-bottom: 8px;
}
.define-timecalc {
    font-size: 11px;
    font-weight: 500;
    padding-top: 8px;
}
 .arrow-img {
    position: relative;
}
.arrow-img img {
    position: absolute;
    top: -7px;
    width: 14px;
    left: 70%;
    background: #fff;
}
.radioBox {
    width: 100%;
    margin: 0;
    padding: 0;
}
.radio-fieldwith-iconlist {
    display: flex;
    align-items: center;
}
.itemsradio {
    margin: 0!important;
    width: 100%;
    flex-basis: 35%;
}
.itemsradio span {
    /* padding: 2px; */
}
label.itemsradio span {
    font-size: 12px;
    font-weight: 600;
}
.flex-servicesbox {
    width: 100%;
    display: flex;
    align-items: center;
}
.zerogap {
    padding: 0!important;
}
ul.servicesBoxs-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ul.servicesBox {
    padding: 0;
    list-style-type: none;
    width: 100%;
    margin: 0;
}
li.servicesBoxs-li {
    padding: 5px;
}
.highlight-price {
    font-size: 17px;
    font-weight: 800;
    min-width: max-content;
}
ul.listicon {
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}
li.listitemsicon img {
    width: 15px;
    margin: 2px;
    cursor: pointer;
}
button.btnBook {
    font-size: 14px;
    width: 100%;
    background: #0d9310!important;
    color: #fff;
    margin: 5px 0;
}

.tabsBox {
    margin: 15px;
    background: #F5F4F4;
    position: relative;
    border-radius: 7px;
}
.closebox {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.bgflightdetailscard {
    padding: 10px 10px 0px;
    margin: 15px 0px;
    background: #fff;
    box-shadow: 3px 2px 4px 1px #e9ecef;
    border-radius: 7px;
    overflow: hidden;
}
span.fromname, span.toname  {
    font-size: 15px;
    font-weight: 600;
}
span.dateformto {
    font-size: 13px;
    font-weight: 400;
    color: #707070;
}
.to-fromtlt span {
    padding: 0 5px;
}
span.icoarrowfromto img {
    width: 35px;
    opacity: .8;
}
.tltdate {
    font-size: 12px;
    font-weight: 600;
}
.flightairportaddress p {
    font-size: 11px;
    color: #999;
}
.arrowtabtxt {
    text-align: center;
    font-size: 11px;
}
.arrowtabinsidebox {
    position: relative;
}
.arrowtabinsidebox img {
    position: absolute;
    top: 0;
    width: 100px;
}
.arrowtabinsidebox img {
    position: absolute;
    top: 4px;
    width: 75px;
    opacity: .7;
    right: 26px;
}
.flightairportaddress {
    padding-left: 20px;
}
.to-fromtlt {
    margin-bottom: 15px;
}
.layoverbox {
    border-bottom: 1px solid #ccc;
    margin: 15px 0;
    position: relative;
    width: 100%;
}
.layover {
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 25px;
    color: orangered;
    background: #ffffff;
    top: -14px;
    left: 40%;
    border-radius: 8px
}
.tabpanelsflightpanel>div {
    padding: 10px;
}
.time-calc {
    font-size: 14px;
    font-weight: 700;
}
.type {
    font-size: 13px;
    font-weight: 400;
    color: #999;
}
.seathighighted {
    font-size: 13px;
    font-weight: 400;
    color: #999;
}
span.seathightlighted {
    color: #dc3545;
    padding-left: 5px;
}
.tltBaggage {
    font-size: 11px;
    font-weight: 600;
    color: #444;
    text-transform: uppercase;
}
.tltBaggage-desc {
    font-size: 13px;
    color: #6b6868;
}

.tlt-total{
    font-weight: 600;
}
.headerRow {
    padding-bottom: 5px;
}
.tltfaredetails {
    font-size: 14px;
    font-weight: 500;
    color: #585656;
}
span.note-qt {
    color: #9a9a9a;
    font-size: 13px;
}
.totalRow {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.shownet {
    font-size: 15px;
    font-weight: 500;
    margin-left: 35%;
    color: #444;
    margin-top: 0px;
    cursor: pointer;
    display: inline-flex;
}

.tooltiphovercnt {
    font-size: 14px;
    color: #000;
}
.shownetmulticities {
    font-weight: 500;
    margin-left: 48%;
    margin-top: 1px;
    cursor: pointer;
    width: max-content;
}
.shownet-itr{
    font-weight: 500;
    margin-left: 20%;
    margin-top: 1px;
    cursor: pointer;
    width: max-content;
}
.titlepricetag {
    display: flex;
    align-items: center;
}
.itemssecttag {
    font-size: 10px;
    font-weight: 600;
    margin-right: 10px;
    width: max-content;
}
.itemssecttag-supplier{
    font-size: 10px;
    font-weight: 600;
    margin-right: 10px;
    color: #999;
    white-space: nowrap;
    width: 100px;
    overflow: hidden ! important;
    text-overflow: ellipsis;
    cursor: pointer;
}

.itemssecttag-supplier:hover {
    position: relative;
    white-space: nowrap;
    width: max-content;
    background: #fff;
    overflow: auto;
    z-index: 9999;
    text-overflow: inherit ;

}
.itemssecttag-book{
    font-size: 10px;
    font-weight: 600;
    margin-right: 10px;
}

.Noresult {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    padding: 25px;
    margin: 25px 0;
    background: aliceblue;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrowtabinfo {
    position:relative;
   }
   .arrowtabinfo img {
    position: absolute;
    top: 4px;
    left: 45px;
}
.define-timeinfo {
    font-size: 11px;
    font-weight: 500;
    padding-top: 14px;
    text-align: center;
}
.infinite-scroll-component {
    overflow-x: hidden !important;
}

.viewresultbooking.addonbackground.row {
    background: #dee2e6;
    width: 100%;
    margin-left: 0px;
}

.available-seats{
    /* border: 1px solid #0c930f; */
    /* padding: 3px; */
    font-size: 11px;
    border-radius: 4px;
    color: #28a745;
    font-weight: 600;
    text-transform: capitalize;
}

.more-flights-btn{
    font-size: 12px;
    color: #e81d2a;
    font-weight: 600;
    padding: 5px 10px;
    cursor: pointer;

}

.viewdetailwrp{
    text-align: end;
}
.view-more-btn{
    font-size: 12px;
    color: #444;
    font-weight: 600;
    padding: 5px 10px;
    cursor: pointer;
    float: right;
    width: max-content;
}
.viewmore-box{
    border-top: 1px solid #e9ecef;
    padding: 3px 0px;
}

button.btnBookOutline {
    font-size: 14px;
    width: 100%;
    background: #fff !important;
    color: #0d9310 !important;
    margin: 5px 0;
    border: 1px solid #0d9310;
    padding: 2px !important;
    box-shadow: none !important;
    padding: 5px !important;
}

button.btnBookOutline:hover {
    font-size: 14px;
    width: 100%;
    background: #0d9310!important;
    color: #fff !important;
}

.arrow-line{
    border-bottom: 1px dotted #6c757d;
    display: block;
    height: 1px;
    width: 45%;
    margin: auto;
}

.arrow-down-rotate{
    transform: rotate(180deg);
}
.m-inherit{
    margin: inherit;
}
.info-label{
    position: absolute;
    color: #fff;
    font-size: 9.5px;
    font-weight: 500;
    padding: 2px 10px;
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 2px;
    z-index: 9;
}

.info-label-1{
    width: 0;
    position: absolute;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 3.5px solid transparent;
    border-right: 6px solid #6c757d;
    margin-left: -16px;
    margin-top: -7px;
}

.shortdistance-info-bg{
    background: #7abb4b;
}
.dealavailable-info-bg{
    background: orange;
}

.longdistance-info-bg{
    background: #f26a53;
}
.pricewidth{
    width: 50%;
}
/* 
.seats-count {
    position: absolute;
    bottom: -20px;
} */

.fare-rule-icon{
    margin-top: -3px;
    font-size: 17px !important;
    cursor: pointer;
    color: #888;
}

.multicityresult .definewidth {
    margin: 3px;
    width: 15%;
}

.multicityresult .define-stop, .multicityresult .define-timecalc {
    text-align: center;
}

.tltBaggage-sm {
    font-size: 12px;
    color: #000;
    font-weight: 600;
}

.tltfaretype-desc{
    position: absolute;
    right: 6%;
    top: 15px;
    font-size: 13px;
    color: #000;
}
.tltfaretype-round{
    padding: 10px 15px 0px;
    font-size: 12px;
    color: #000;
}
.tltfaretype-round .tltBaggage-sm {
    font-size: 12px;
    color: #000;
    font-weight: 600;
}
.table-copy{
    position: fixed;
    left: -999em;
    background-color: #fff !important;
    width: max-content;
}

.table-copy.table-modal{
    position: relative;
    left: 0;
    background-color: transparent !important;
    width: 100%;
}

.text-small{
    font-size: 12px;
}

.table-copy table{
    background-color: #fff !important;
    width: max-content;
    overflow: auto;
}

.table-copy.table-modal table{
    background-color: #fff !important;
    width: 100%;
    overflow: auto;
}
.modalCancelGF{
    font-size: 28px !important;
    cursor: pointer;
}

.table-copy th{
    font-size: 13px;
    border: 1px solid #ccc !important;
    background-color: #f1f3f4 !important;
    padding: 4px 10px;
}

.table-copy td{
    font-size: 14px;
    width: max-content;
    border: 1px solid #ccc !important;
    padding: 4px 10px;
}

.table-copy p{
    margin-top: 10px;
    font-size: 13px;
    color: #444;
}

.showFixedFilter {
    display: block;
    position: fixed;
    max-width: 276px;
    width: 100%;
    background: #fff;
    top: 65px;
    margin-left: -1.1%;
    padding: 15px;
    border-radius: 0px 0px 10px 10px;
    
}

.showFixedFilter .airlines-lists{
    background:#fff;
}