
.filter-content{
    width: 100%;
    background: #e81d2a08;
    padding: 15px;
    border-radius: 8px;
    margin-top: 0px;
    box-shadow: 0px 2px 3px 0px #ccc;
    position: sticky;
    top: 30px;
}
.filter2 {
    width: 100%;
    height: 2vh;
    background: antiquewhite;
    margin-top: 25px;
    margin-bottom: 5px;
}
.filter3 {
    width: 100%;
    height: 10vh;
    background: #f3c7cf;
    margin-top: 25px;
    margin-bottom: 5px;
}
.tlt-filter-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.tltrangereset {
    font-size: 14px;
    font-weight: 500;
    color: tomato;
    width: 55%;
    cursor: pointer;
    text-align: right;
}
.tltrange-minimize {
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
}
.filter-msg-output {
    font-size: 12px;
    font-weight: 500;
    color: #444;
    padding: 10px 0px;
}
.filter-msg-output-multi{
    padding: 10px 15px;
}

.roundtripresult-des  .filter-msg-output {
    font-size: 12px;
    font-weight: 500;
    color: #444;
    padding: 0px 0px 10px;
}
.flitercolumstick {
    position: sticky;
    top: 0;
}

/* .flightresultcontainer {
    max-width: 1390px!important;
} */


.setbtnprevnext {
    align-items: center;
    display: flex;
    justify-content: end;
    margin-top: 10px;
}
.setbtnprevnext-w{
    display: flex;
    margin-top: 5px;
}
.setbtnprevnext-multi{
    margin-top: 0px ;
}
.next-btn {
    font-size: 13px;
    font-weight: 700;
    color: #e81d2a;
    margin-left: 7px;
    cursor: pointer;
    user-select: none;
}
.prev-btn {
    font-size: 13px;
    font-weight: 700;
    color: #e81d2a;
    margin-right: 7px;
    cursor: pointer;
    user-select: none;
}
.next-btn:hover, .prev-btn:hover {
color: #fc9f14;
}
.pipevrt {
    font-size: 15px;
    font-weight: 700;
    margin-top: -5px;
}

.share-container-round .mb-0.row{
    margin-top: 20px;
}
.share-container .mb-0.row{
    margin-top: 20px;
}

.share-container{
    position: fixed;
    bottom: 0px;
    width: 340px;
    right: 0;
    background: #000;
    z-index: 9;
    border-radius: 15px 15px 0px 0px;
    padding: 15px 25px;
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}
.share-row-wrapper{
   margin-top: -20px;
   padding: 0px 15px;
}
.share-img{
   margin: 2px;
   margin-bottom: 12px;
}
.share-img  img{
    width: 40px;
    border-radius: 6px;
}
.share-ttl-1{
    color: #fff;
    font-weight: 500;
    margin-bottom: 3px;
    font-size: 13px;
}
.share-price-input input{
    border-bottom: 1px solid #fff;
    height: 20px !important;
    margin-left: 14px;
    color: #fff;
    font-size: 13px;
}

.share-text-input input{
    border-bottom: 1px solid #fff;
    height: 20px !important;
    color: #fff;
    font-size: 13px;
    margin-top: 10px;
}

.share-text-input{
    width: 100%;
}

.share-cross-btn {
    background: transparent !important;
    border: none;
    padding: 0px;
    float: right;
    margin-top: 5px;
    font-weight: 700;
    z-index: 99;
}

.share-btn {
    width: 100%;
    margin-top: 15px;
    font-size: 15px;
    padding: 3px;
}

.btn-green{
    background: #0c930f !important;
    border-color: #0c930f !important;
}

.share-img-wrapper{
    margin: 5px 0px 10px;
}

.share-txtbtn {
    text-align: center;
    font-size: 11px;
    font-weight: 500;
}

.whatsapp-icon {
   color: #fff;
   font-size: 17px !important;
}


.share-checkbox svg.MuiSvgIcon-root {
    font-size: 17px !important;
    color: #28a745 !important;
}

.share-cbx{
    text-align: center;
    border-radius: 3px;
    padding: 0px !important;
    margin-left: -20%;
    position: absolute;
    top: 0%;
}
.share-cbx-irt{
    margin-left: -10%;
}
.share-icon-box{
    background: #28a745;
    padding: 5px;
    color: #fff;
    line-height: 15px;
}

.share-outline-input{
    /* border: 1px solid #fff; */
    width: 100%;
    color: #fff !important;
}
.share-checkbox svg{
    z-index: 0;
}
.share-container-round{
    position: fixed;
    bottom: 76px;
    width: 340px;
    right: 0;
    background: #000;
    z-index: 9;
    border-radius: 15px 15px 0px 0px;
    padding: 15px 25px;
    height: 390px;
    overflow-y: auto;
    overflow-x: hidden;
}
.share-container-round .btn-primary {
    color: #fff ;
    background-color: #007bff ;
    border-color: #007bff ;
    border-color: #007bff;
    padding: 3px !important;
}

.share-cbx-round {
    display: flex;
    margin-top: 5px;
    text-align: center;
    border-radius: 3px;
    padding-right: 10px;
}
.whatsapp-share-icon{
    font-size: 13px;
    color: green;
    font-weight: 500;
}
.whatsapp-share-icon svg{
    font-size: 15px !important;
    margin-top: -3px;
}
.whatsapp-share-text svg  {
    color: green;
}
.whatsapp-share-text .Mui-checked svg  {
    color: green;
}

.flight-internation-round .share-cbx {
    margin-left: -12%;
}

.heading-left{
    flex-basis: 50%;
}
.heading-right{
    flex-basis: 50%;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    display: flex;
}
