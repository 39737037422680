@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body{
    font-family: 'Roboto', sans-serif;
}

main{
    background-image: url(./assets/images/white-bg.jpg);
    padding-top: 20px;
    padding-bottom: 60px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 30px;
}

.text-small{
    font-size: 12px !important;
}
.text-sm{
    font-size: 15px;
}
a {
    color: inherit !important;
    text-decoration: none !important;
}
.MuiFormHelperText-root.Mui-error , .MuiFormHelperText-root{
    margin: 0 !important;
    text-transform: capitalize;
    font-size: 12px !important;
}

.MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
}
.setBox .MuiAutocomplete-inputRoot{
    padding: 3px 10px !important;
}
.MuiStack-root{
    padding-top: 0px !important;
    overflow: hidden !important;
}

.MuiStack-root .MuiFormControl-root{
    width: 100% !important;
    min-width: 100% !important;
}

.float-right{
    float: right;
}
.MuiButton-root{
    font-weight: 600 !important;
    font-size: 12px !important;
    box-shadow: none !important;
}
.MuiButton-root:hover{
    font-weight: 600 !important;
    font-size: 13px !important;
    box-shadow: none !important;
    transition: ease-in-out 0.4s;
}

.MuiPickersDay-root.Mui-selected{
    background-color: #e71d2a !important;
}
.MuiButton-textPrimary{
    color: #e71d2a !important;
}
.MuiStack-root svg{
    width: 18px;
}

.MuiButton-containedPrimary{
    background-image: linear-gradient(310deg,#e81d2a,#ca1bbbe6) !important;
    /* border-color: #D33A2C !important; */
    color: #fff !important; 
   
}

.MuiButton-containedError{
    background-image: linear-gradient(310deg,#e81d2a,#f27373) !important;

    /* background-color: #FFE7E7 !important; */
    /* border-color: #D33A2C !important; */
    color: #fff !important; 
}


.MuiButton-containedSuccess{
    background-image: linear-gradient(310deg,#17ad37,#98ec2d) !important;
    color: #fff !important;
}

.header-main header{
    background-color: #e71d2a;
    
}

.header-main .MuiToolbar-root {
    min-height: 50px;
}

.MuiAvatar-root {
    background-color: #fff !important;
    color: #e81d2a !important;
}
.MuiMenuItem-root{
    font-size: 15px !important;
}
.sidenav-list svg{
    font-size: 30px !important;
    box-shadow: 0 .3125rem .625rem 0 rgba(0,0,0,.12)!important;
    padding: 7px;
    border-radius: 5px;
}
.sidenav-list .MuiTypography-root{
    font-size: 15px !important;
}

.error-border fieldset{
    border-color: #d32f2f !important;
}

table tr, table td{
    padding: 8px 16px !important;
    font-size: 14px !important;
}
table th{
    background: #eeeeee6b;
    padding: 6px 16px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
}
table button{
    font-size: 12px !important;
    padding: 3px 10px !important;
    min-width: max-content !important;
}

table .MuiButtonBase-root svg{
    width: 17px;
    height: 20px;
}

.modal-dialog{
    background: #fff;
    overflow: hidden;
    min-width: 700px;
    padding: 10px 25px;
    margin: auto;
    /* border-radius: 6px; */
    margin-top: 50px !important;
}
.modal-dialog .modal-content{
    border: none !important;
    display: block !important;
}
.modal-dialog object{
    height: 70vh !important;
}
.modal-dialog .modal-content img{
    max-width: 600px;
}
.modal-close-btn{
    /* position: absolute !important;
    right: -25px;
    top: -25px; */
    width: max-content;
    /* float: right; */
}
.modal-close-btn svg{
    font-size: 20px;
    margin-right: 3px;
}
.MuiStepper-root{
    width: 60%;
    margin: auto;
}
.MuiStepLabel-root svg{
    color: #e81d2a59 ;
    font-size: 32px;
    margin: auto;

}
.MuiStepLabel-root svg.Mui-active{
    color: #d32f2f;
    font-size: 34px;
}
.MuiStepLabel-root{
    display: block !important;
}
.MuiStepLabel-iconContainer{
    display: block !important;
    padding: 5px !important;
    margin: auto;
}
.MuiStepper-root .MuiStepConnector-line {
    display: block;
    border-color: #e81d2a42;
    border-top-style: solid;
    border-top-width: 3px;
}
.border-radius-20{
    border-radius: 20px;
}
.MuiAutocomplete-listbox li{
    font-size: 15px;
}
.MuiListItemIcon-root{
    margin-right: 12px !important;
}
.MuiPopover-paper{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px -3px, rgba(0, 0, 0, 0.062) 0px 2px 2px 1px !important;
}


.badge-primary {
    color: #fff;
    background-color: #007bff;
}
.badge-warning {
    color: #212529;
    background-color: #ffc107;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
@media (min-width: 1350px)
{
    .MuiContainer-root {
        max-width: 1330px !important ;
        padding: auto 15px !important;
    }
}
.traveler-name svg{
    font-size: 20px;
    color: #e71d2a;
}
.traveler-name{
    font-size: 15px;
}

.td-label{
    font-size: 13px;
    font-weight: 600;
    color: #000;
}

.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
    color: #28a745;
}

.text-note p{
    font-size: 14px;
    margin: 15px 15px 0px;
}


.MuiAlert-standardWarning{
    border: 1px solid #FFC107;
    padding: 10px;
    border-left: 7px solid #FFC107;
}

.bg-light-red{
    background-color: #ff00000a !important;
    color: #e91d2a;
}