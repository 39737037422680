.logo-img{
    width: 130px;
}
.logo-img-xs{
    display: none;
}
.bg-body-tertiary.navbar{
    background-color: #fff !important;
    box-shadow: 0 -1px 3px rgba(0,0,0,.15);
    padding: 2px 0;
}
.header-main h1{
    font-size: 18px;
}
.header-main .MuiPaper-root{
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15)!important;
    border-right: 0px !important;
    z-index: 99 !important;
}