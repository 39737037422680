.user-container{
    background-image: url(../../assets/images/white-bg.jpg);
    padding-top: 20px;
    padding-bottom: 60px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.login-container{
    padding-top: 80px;
    padding-bottom: 100px;
    height: 85vh;
}

.login-outer-box{
    background: #fff;
    padding: 30px;
    /* margin-top: 40px; */
    box-shadow: 0 14px 24px rgba(0,0,0,.06);
}

.signin-footer a{
    color: #000;
    text-decoration: none;
}

.reg-checkbox span{
    font-size: 11px;
}

/* 
.otp-box-wrp{
    height: 0;
    overflow: hidden;
    background: red;
   -prefix-animation: slide 1s ease 3.5s forwards;
}

@-prefix-keyframes slide {
  from {height: 0;}
  to {height: 300px;}
} */

.otp-box{
    display: block;
}

.otp-box-hide{
    display: none;
}

.animate {
    animation: fadeIn 700ms ease-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      transform: translateX(250px);
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  .btnSignUp .MuiCircularProgress-root{
    width: 30px !important;
    height: 30px !important;
    color: #fff;
    padding: 2px;
  }