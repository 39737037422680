.flightbookingloader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    text-align: center;
    justify-content: center;
    background: #f3f5f7;

}
.texthighlited {
    width: 100%;
    text-align: center;
    font-weight: 600;;
    margin-top: 10px;

}