.order-card {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#1d79e2,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#27bd9f,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#e49e3a,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#e63150,#ff869a);
}


.card {
    border-radius: 20px !important;
    -webkit-box-shadow: 0 20px 27px 0 rgba(0,0,0,.05);
    box-shadow: 0 20px 27px 0 rgba(0,0,0,.05);
    border: none !important;
    /* margin-bottom: 30px; */
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

}

.card .card-block {
    padding: 25px;
}

.order-card i {
    font-size: 26px;
}
.card-block h2{
    font-size: 36px;
}
.f-left {
    float: left;
}

.f-right {
    float: right;
}

.card-block h2, .card-block h6, .card-block p{
    color: #fff;
}


.calendarBox {
    /* padding: 15px; */
    height: 60%;
    border-radius: 10px;
    background-color: #fff;
}

.fc .fc-button-primary {
    background-color: #e81d2a;
    border-color: #F44336;
    color: #fff;
}

.fc .fc-toolbar-title {
    font-size: 22px;
}

.fc .fc-button{
    cursor: pointer;
    font-size: 15px;
}

.fc .fc-toolbar.fc-header-toolbar{
    margin: 0;
    padding: 20px;
    background: #fff;
}

.calendarBox table th {
    padding: 1px 16px !important;
    background: #fff;
    color: #e81d2a;
}