.activity-flight .searchtab-main{
    width: 30%;
    min-height: 30px
}

.activity-flight .searchtab-main button{
    font-size: 11px;
    min-height: 30px;
    display: flex;
    flex-direction: revert;
}
.activity-flight .searchtab-main button svg{
    margin-right: 5px;
    margin-bottom: 0;
}
.activity-flight .box-tlt {
    font-size: 10px;
    margin-bottom: 0;
    flex-basis: 100%;
}

.flight-modal .modal-dialog {
    max-width: 1200px;
}

.images-modal .modal-dialog {
    max-width: 1100px;
}

.flight-modal .modal-body{
    padding: 30px 15px ;
}
.activity-flight .swipebox{
    /* padding: 0; */
    /* margin-top: 18px */
    text-align: center;
}

/* .search-wpr {
    display: flex;
    border: none !important;
    margin: 8px !important;
    padding: 5px 0px !important;
} */

.search-wpr .box-input-passclass {
    padding-top: 10px;
    flex-basis: 65% !important;
}

.activity-flight .box-input-w p{
    font-size: 14px;
    color: #000;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 0;
}

.box-input-w .MuiOutlinedInput-root{
    padding: 3px !important;
    border: 1px solid #e81d2a3b;
}

.box-input-calender .MuiOutlinedInput-root{
    border: 1px solid #e81d2a3b;
}

.activity-flight .btnSend {
    font-size: 12px !important;
    width: max-content;
    border-radius: 20px !important;
    padding: 4px 20px !important;
}

.prstColl {
    position: relative;
    padding-right: 5px !important;
}

.inputDate {
    margin-top: 6px!important;
}

.trav-txt {
    font-size: 12px;
    color: #000;
    text-transform: capitalize;
    margin-top: 5px;
    font-weight: 600;
}
.searchsuggestionbox {
    padding: 0 5px;
    font-size: 13px;
}

.searchsuggestionbox {
    padding: 5px;
}
.flightdetails-code {
    display: flex;
    align-items: center;
}
.cityfly {
    flex-basis: 70%;
    font-size: 13px;
    font-weight: 600;
}
.airport-name {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 190px;
}
.airportcode {
    flex-basis: 30%;
    font-size: 14px;
    font-weight: 600;
}
.passclass-box {
    position: absolute;
    background: #fff;
    width: 210px;
    padding: 15px;
    top: 90%;
    z-index: 1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.btnGroup{
    margin-bottom: 5px;
}

.passclass-btn{
    display: block;
    margin-top: 4px;
    font-weight: 700;
    color: #949494;
    text-transform: capitalize;
    font-size: 15px;
    cursor: pointer;
}
.passclass-box .box-tlt{
    padding: 0px !important;
}
.passclass-box .MuiBox-root {
    padding:  0 10px  !important;
}

.passclass-box .MuiBox-root .MuiBox-root{
    padding:  0  !important;
}

.passclass-box .class-wrp .MuiSelect-select{
    padding: 0px 10px !important;
    font-size: 12px !important;
}

.flight-heading h1{
    font-size: 16px;
    background: #f2f2f2;
    padding: 10px;
}

/* .zerogutter {
    padding: 0;
} */
.flight-added-wrp .bgflightdetailscard{
    box-shadow: none !important;
}
.flight-added-wrp .acti-flight-list.card{
    box-shadow: none !important;
    padding: 0 !important;
}
.tltreviewheader {
    font-size: 13px;
    font-weight: 500;
    color: e81d2a;
    text-transform: capitalize;
}
.tltreviewheader svg{
    font-size: 17px;
}
.flexset {
    display: flex;
    align-items: center;
    width: 100%;
}
.tltreviewleft {
    font-size: 13px;
    font-weight: 500;
    color: e81d2a;
    flex-basis: 50%;
    text-align: left;
    text-transform: capitalize;
}

.tltreviewheader.leftalign {
    margin-left: -4%;
}
.sliderBox{
    margin: 0;
}
.tltreviewheader.netpricealign {
    margin-left: 12%;
}

.tltreviewcenter {
    font-size: 13px;
    font-weight: 500;
    color: e81d2a;
    flex-basis: 50%;
    text-align: center;
    text-transform: capitalize;
}
.tltreviewright {
    font-size: 13px;
    font-weight: 500;
    color: e81d2a;
    flex-basis: 50%;
    text-align: right;
    text-transform: capitalize;
}
.viewresultbooking {
    padding: 10px 8px 10px;
}
.viewresultbooking.sortby-des {
    padding: 8px !important;
}
.listiconbox{
    text-align: right;
}
.flightimg {
    display: flex;
    align-items: center;
}
.mini-tag {
    width: 100%;
    margin-left: 7px;
}
.flightname {
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    width: 70px;
    overflow: hidden ! important;
    text-overflow: ellipsis;
    cursor:auto;
}

.flightname:hover {
    font-size: 12px;
    position: relative;
    font-weight: 600;
    white-space: nowrap;
    width: auto;
    background: #fff;
    overflow: auto;
    z-index: 9999;
    text-overflow: inherit ;
    padding-right:  20px;

}
.flightnumber {
    font-size: 12px;
    font-weight: 500;
}
.viewbtndetails {
    margin-top: 27px;
}
.minitxt {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #dc3545;
}
.definewidth {
    margin: 3px;
}
.arrowbox {
    width: 150px;
    margin: 3px;
    text-align: center;
}
.flightmininame {
    font-size: 10px;
    font-weight: 600;
    color: #999;
}
.flightminitime {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.flightminidata {
    font-size: 8px;
    font-weight: 600;
    color: #999;
}
.define-stop {
    font-size: 11px;
    font-weight: 500;
    color: #000;
    padding-bottom: 8px;
}
.define-timecalc {
    font-size: 11px;
    font-weight: 500;
    padding-top: 8px;
}
 .arrow-img {
    position: relative;
}
.arrow-img img {
    position: absolute;
    top: -7px;
    width: 14px;
    left: 70%;
    background: #fff;
}
.radioBox {
    width: 100%;
    margin: 0;
    padding: 0;
}
.radio-fieldwith-iconlist {
    display: flex;
    align-items: center;
}
.itemsradio {
    margin: 0!important;
    width: 100%;
    flex-basis: 35%;
}
.itemsradio span {
    /* padding: 2px; */
}
label.itemsradio span {
    font-size: 12px;
    font-weight: 600;
}
.flex-servicesbox {
    width: 100%;
    display: flex;
    align-items: center;
}
.zerogap {
    padding: 0!important;
}

.servicesBoxs{
    /* margin-left: 20px; */
}
ul.servicesBoxs-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ul.servicesBox {
    padding: 0;
    list-style-type: none;
    width: 100%;
    margin: 0;
}
li.servicesBoxs-li {
    padding: 5px;
}
.highlight-price {
    font-size: 17px;
    font-weight: 700;
    min-width: max-content;
}
ul.listicon {
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}
li.listitemsicon img {
    width: 15px;
    margin: 2px;
    cursor: pointer;
}
button.btnBook {
    font-size: 11px;
    width: 100%;
    background: #0d9310!important;
    color: #fff;
    padding: 10px !important;
    margin: 5px 0;
    height: 30px;
}

.tabsBox {
    margin: 15px;
    background: #F5F4F4;
    position: relative;
    border-radius: 7px;
}
.closebox {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.closebox svg{
    font-size: 13px !important;
    color: red;
}
.bgflightdetailscard {
    /* padding: 10px 10px 0px; */
    margin: 0px 0px;
    background: #fff;
    box-shadow: 3px 2px 4px 1px #e9ecef;
    border-radius: 7px;
}
span.fromname, span.toname  {
    font-size: 15px;
    font-weight: 600;
}
span.dateformto {
    font-size: 13px;
    font-weight: 400;
    color: #707070;
}
.to-fromtlt span {
    padding: 0 5px;
}
span.icoarrowfromto img {
    width: 35px;
    opacity: .8;
}
.tltdate {
    font-size: 14px;
    font-weight: 600;
}
.flightairportaddress p {
    font-size: 11px;
    color: #999;
}
.arrowtabtxt {
    text-align: center;
    font-size: 13px;
}
.arrowtabinsidebox {
    position: relative;
}
.arrowtabinsidebox img {
    position: absolute;
    top: 0;
    width: 100px;
}
.arrowtabinsidebox img {
    position: absolute;
    top: 4px;
    width: 75px;
    opacity: .7;
    right: 26px;
}
.flightairportaddress {
    padding-left: 20px;
}
.to-fromtlt {
    margin-bottom: 15px;
}
.layoverbox {
    border-bottom: 1px solid #ccc;
    margin: 15px 0;
    position: relative;
    width: 100%;
}
.layover {
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 25px;
    color: orangered;
    background: #ffffff;
    top: -14px;
    left: 40%;
    border-radius: 8px
}
.tabpanelsflightpanel>div {
    padding: 10px;
}
.time-calc {
    font-size: 14px;
    font-weight: 700;
}
.type {
    font-size: 13px;
    font-weight: 400;
    color: #999;
}
.seathighighted {
    font-size: 13px;
    font-weight: 400;
    color: #999;
}
span.seathightlighted {
    color: #dc3545;
    padding-left: 5px;
}
.tltBaggage {
    font-size: 13px;
    font-weight: 600;
    color: #444;
    text-transform: uppercase;
}
.tltBaggage-desc {
    font-size: 15px;
    color: #6b6868;
}

.tlt-total{
    font-weight: 600;
}
.headerRow {
    padding-bottom: 5px;
}
.tltfaredetails {
    font-size: 14px;
    font-weight: 500;
    color: #585656;
}
span.note-qt {
    color: #9a9a9a;
    font-size: 13px;
}
.totalRow {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.shownet {
    font-size: 15px;
    font-weight: 500;
    margin-left: 35%;
    color: #444;
    margin-top: 0px;
    cursor: pointer;
    display: inline-flex;
}

.tooltiphovercnt {
    font-size: 14px;
    color: #000;
}
.shownetmulticities {
    font-weight: 500;
    margin-left: 48%;
    margin-top: 1px;
    cursor: pointer;
    width: max-content;
}
.shownet-itr{
    font-weight: 500;
    margin-left: 20%;
    margin-top: 1px;
    cursor: pointer;
    width: max-content;
}
.titlepricetag {
    display: flex;
    align-items: center;
}
.itemssecttag {
    font-size: 10px;
    font-weight: 600;
    margin-right: 10px;
    width: max-content;
}
.itemssecttag-supplier{
    font-size: 10px;
    font-weight: 700;
    margin-right: 10px;
    color: #999;
    white-space: nowrap;
    width: 100px;
    overflow: hidden ! important;
    text-overflow: ellipsis;
    cursor: pointer;
}

.itemssecttag-supplier:hover {
    position: relative;
    white-space: nowrap;
    width: max-content;
    background: #fff;
    overflow: auto;
    z-index: 9999;
    text-overflow: inherit ;

}
.itemssecttag-book{
    font-size: 10px;
    font-weight: 600;
    margin-right: 10px;
}

.Noresult {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    padding: 25px;
    margin: 25px 0;
    background: aliceblue;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrowtabinfo {
    position:relative;
   }
   .arrowtabinfo img {
    position: absolute;
    top: 4px;
    left: 45px;
}
.define-timeinfo {
    font-size: 11px;
    font-weight: 500;
    padding-top: 18px;
    text-align: center;
}
.infinite-scroll-component {
    overflow-x: hidden !important;
}

.viewresultbooking.addonbackground.row {
    background: #dee2e6;
    width: 100%;
    margin-left: 0px;
}

.available-seats{
    /* border: 1px solid #0c930f; */
    /* padding: 3px; */
    font-size: 11px;
    border-radius: 4px;
    color: #28a745;
    font-weight: 600;
    text-transform: capitalize;
}

.more-flights-btn{
    font-size: 12px;
    color: e81d2a;
    font-weight: 600;
    padding: 5px 10px;
    cursor: pointer;

}

.viewdetailwrp{
    text-align: end;
}
.view-more-btn{
    font-size: 12px;
    color: #444;
    font-weight: 600;
    padding: 5px 10px;
    cursor: pointer;
    float: right;
    width: max-content;
}
.viewmore-box{
    border-top: 1px solid #e9ecef;
    padding: 3px 0px;
}

button.btnBookOutline {
    font-size: 14px;
    width: 100%;
    background: #fff !important;
    color: #0d9310 !important;
    margin: 5px 0;
    border: 1px solid #0d9310;
    padding: 2px !important;
    box-shadow: none !important;
    padding: 5px !important;
}

button.btnBookOutline:hover {
    font-size: 14px;
    width: 100%;
    background: #0d9310!important;
    color: #fff !important;
}

.arrow-line{
    border-bottom: 1px dotted #6c757d;
    display: block;
    height: 1px;
    width: 45%;
    margin: auto;
}

.arrow-down-rotate{
    transform: rotate(180deg);
}
.m-inherit{
    margin: inherit;
}
.info-label{
    position: absolute;
    color: #fff;
    font-size: 9.5px;
    font-weight: 500;
    padding: 2px 10px;
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 2px;
    z-index: 9;
}

.info-label-1{
    width: 0;
    position: absolute;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 3.5px solid transparent;
    border-right: 6px solid #6c757d;
    margin-left: -16px;
    margin-top: -7px;
}

.shortdistance-info-bg{
    background: #7abb4b;
}
.dealavailable-info-bg{
    background: orange;
}

.longdistance-info-bg{
    background: #f26a53;
}
.pricewidth{
    width: 50%;
}
/* 
.seats-count {
    position: absolute;
    bottom: -20px;
} */

.fare-rule-icon{
    margin-top: -3px;
    font-size: 17px !important;
    cursor: pointer;
    color: #888;
}

.multicityresult .definewidth {
    margin: 3px;
    width: 15%;
}

.multicityresult .define-stop, .multicityresult .define-timecalc {
    text-align: center;
}

.tltBaggage-sm {
    font-size: 13px;
    color: #000;
    font-weight: 600;
}

.tltfaretype-desc{
    position: absolute;
    right: 6%;
    top: 15px;
    font-size: 13px;
    color: #000;
}
.tltfaretype-round{
    padding: 10px 15px 0px;
    font-size: 12px;
    color: #000;
}
.tltfaretype-round .tltBaggage-sm {
    font-size: 12px;
    color: #000;
    font-weight: 600;
}
.table-copy{
    position: fixed;
    left: -999em;
    background-color: #fff !important;
    width: max-content;
}

.table-copy.table-modal{
    position: relative;
    left: 0;
    background-color: transparent !important;
    width: 100%;
}

.text-small{
    font-size: 12px;
}

.table-copy table{
    background-color: #fff !important;
    width: max-content;
    overflow: auto;
}

.table-copy.table-modal table{
    background-color: #fff !important;
    width: 100%;
    overflow: auto;
}
.modalCancelGF{
    font-size: 28px !important;
    cursor: pointer;
}

.table-copy th{
    font-size: 13px;
    border: 1px solid #ccc !important;
    background-color: #f1f3f4 !important;
    padding: 4px 10px;
}

.table-copy td{
    font-size: 14px;
    width: max-content;
    border: 1px solid #ccc !important;
    padding: 4px 10px;
}

.table-copy p{
    margin-top: 10px;
    font-size: 13px;
    color: #444;
}

.showFixedFilter {
    display: block;
    position: fixed;
    max-width: 276px;
    width: 100%;
    background: #fff;
    top: 65px;
    margin-left: -1.1%;
    padding: 15px;
    border-radius: 0px 0px 10px 10px;
    
}

.showFixedFilter .airlines-lists{
    background:#fff;
}

.filter-content{
    background: #e81d2a08;
    padding: 15px;
    margin-top: 15px;
}

.tripheading{
    background: e81d2a;
    color: #fff;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 500;
}
.tltrange-filter {
    flex-basis: 90%;
    font-size: 16px;
    font-weight: 600;
}

.tlt-filter-header{
    margin-bottom: 10px;
}

.inputDate input {
    font-size: 12px!important;
    padding: 6px 10px;
}

.icon-time span {
    font-size: 12px;
    padding: 5px;
}


.popover-inner{
    position: relative;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}
.ttl-pax{
    padding: 0px 10px;
    font-size: 13px;
}
.flight-overview .popover-inner::before{
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    top: -6px;
    right: 40%;
    background: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 97%;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    z-index: 99;
}

.flight-overview .MuiPaper-root {
    top: 34% !important;
    left: 26.5% !important;
    width: 60% !important;
    padding: 15px 15px 25px
  }
  .view-flight-btn p{
    font-size: 12px;
    padding-top: 6px;
  }
  .btn-success{
    font-size: 12px !important;
    border: 1px solid forestgreen !important;
    padding: 3px 10px !important;
    border-radius: 20px !important;
    color: forestgreen !important;
    display: inline-block;
    background: #fff !important;

  }

  .btn-error{
    font-size: 12px !important;
    border: 1px solid red !important;
    padding: 3px 10px !important;
    border-radius: 20px !important;
    color: red !important;
    background: #fff !important;
  }

  .btn-success:hover{
    font-size: 12px !important;
    padding: 3px 10px !important;
    border-radius: 20px !important;
    color: #fff !important;
    display: inline-block;
    background: forestgreen !important;
  }

  .btn-error:hover{
    font-size: 12px !important;
    padding: 3px 10px !important;
    border-radius: 20px !important;
    color: #fff !important;
    display: inline-block;
    background: red !important;
  }

  .flightresultcontainer, .activity-list {
    padding-bottom: 50px!important;
}

.acti-flight-list {
    height: auto;
    max-height: 450px;
    overflow-y: auto;
}
.btn-close{
    float: right;
    min-width: 20px !important;
    height: 20px !important;
    display: block;
    position: absolute !important;
    right: 22px;
    top: 5px;
    z-index: 999;
    background-color: red !important;
    border: 1px solid !important;
    border-radius: 50% !important;
    font-size: 10px;
    color: #fff !important;
}

.notfoundddata {
    text-align: right;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
  }
  .imgdata {
    width: 100px
  }
  .para {
    font-size: 19px;
    font-weight: 700;
  }
  .minipara {
    font-size: 14px;
    color: #808080
  }
  .btnresetfilter {
    background-color: #fff !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    color: e81d2a !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-top: 10px !important
  }
  .filtererrwrp {
    background-color: '#fff'
  }
  .errfiltertxt{
    padding: 20px
  }

@media (max-width: 786px){
    .flight-modal{
        padding: 0px !important;
    }

    .activity-flight{
        padding: 0px;
    }

    .activity-flight .box-input-w p {
        font-size: 9px;
    }
    .activity-flight .btnSend{
        font-size: 8px !important;
    }


    .search-wpr input {
        font-size: 13px !important;
    }
    .activity-flight .swipebox{
        left: 45%;
        transform: rotate(90deg);
        width: 20px;
        margin-top: -2% !important;
    }
    .icoswipe-flightmodal{
        position: absolute;
        left: -50%;
        top: -17px;
    }
    .passclass-box div{
        padding: 2px 0;
    }
    .mob-input-border-r .d-flex{
        display: block !important;
    }
    .flightresultcontainer, .activity-list {
        padding: 0px 0px 30px!important;
    }
    .search-wpr .swpicon-w-round, .box-swpicon-w{
        position: relative;
    }
    .pl-xs-0{
        padding-left: 0px !important;
    }
    .pr-xs-0{
        padding-right: 0px !important;
    }
    .class-txt{
        margin-bottom: 0;
    }
    .para {
        font-size: 14px;
    }
    .minipara {
        font-size: 12px;
    }
    .errfiltertxt {
        padding: 20px 0px;
    }

    button.btnBook , button.btnBookOutline{
        width: 30% !important;
        float: right;
        height: 23px !important;
        font-size: 10px !important;
    }
    .tabsBox {
        margin: 0px -25px;
    }
    
    span.fromname, span.toname, span.dateformto{
        font-size: 10px;
    }
    .tltdate {
        font-size: 9px !important;
        margin-top: 5px;
    }
    .tltfaredetails {
        font-size: 9px !important;
    }

    span.note-qt {
        font-size: 8px !important;
    }
    .tlt-total {
        font-size: 14px;
    }
    .radioBox {
        padding: 10px 0px 15px !important;
    }

    .flitercolumstick-left{
        padding: 0px !important;
    }
    .flight_details_btn {
        display: flex;
    text-align: right;
    justify-content: flex-end;
    }
    .price {
        font-size: 12px !important;
        font-weight: 600;
        margin-left: -10px;
        margin-right: 10px;
    }

    .flight_details_btn .price span {
        font-size: 15px;
        margin-left: 0px !important;
    }
    .pricetotal{
        margin-right: 20px !important;
        font-size: 13px;
    }
    .footerbookingflightname {
        font-size: 8px !important;
    }
    .tripheading{
        font-size: 11px !important;
    }
}

.package-ttl-1 svg{
    font-size: 18px;
    margin-right: 5px;
}
.package-ttl-1 span{
    font-size: 15px;
}
.add-flight-btn p{
    font-size: 14px;
    color: red;
}

.activity-flight .searchtab-main {
    width: 30%;
    min-height: 30px;
}
div#nav-tabpanel-2, div#nav-tabpanel-1, div#nav-tabpanel-0 {
    background: #fff;
}
.search-wpr {
    display: flex;
    padding: 10px 15px !important;
    border-radius: 20px;
    background: #fef7f8;
}
.cntrowswp {
    position: relative;
}
.activity-flight .box-tlt {
    font-size: 10px;
    margin-bottom: 0;
    flex-basis: 100%;
}
.tabsPanelbdr .box-tlt {
    padding: 0 10px !important;
}
.box-tlt {
    font-size: 15px;
    font-weight: 600;
    color: e81d2a;
    text-transform: uppercase;
    margin-bottom: 5px!important;
}
.inputFieldLocation {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 3px!important;
    margin-bottom: 5px!important;
    border-radius: 8px;
}

.box-input-w .MuiOutlinedInput-root {
    padding: 3px !important;
    border: 1px solid #e81d2a3b;
}
.inputFieldLocation input {
    font-size: 13px!important;
}
.search-wpr fieldset {
    border: none !important;
    border-width: none !important;
    border-color: none !important;
}

.text-right{
    text-align: right;
}
.MuiButtonGroup-root .MuiButtonBase-root {
    border-color: #e61e35 !important;
    color: #e61e35;

}
.MuiTabs-root .MuiButtonBase-root{
    border: none !important;
    border-bottom: 1px solid #e61e35 !important;
    color: #e61e35 !important;
}
.MuiTabs-root .MuiButtonBase-root.Mui-selected {
    color: #fff !important;
    border: 1px solid #e61e35;
    background: #e61e35;
}
.MuiTabs-indicator {
    background: #e61e35 !important;
}
.activity-flight .searchtab-main button svg {
    margin-right: 5px;
    margin-bottom: 0;
    font-size: 20px;
}

.activity-flight .searchtab-main button {
    font-size: 11px;
    min-height: 30px;
    display: flex;
    flex-direction: revert;
}

label.nonstop-tlt span {
    font-size: 12px!important;
    font-weight: 600;
}
.nonstop-tlt .MuiCheckbox-root {
    padding: 9px;
    font-size: 12px!important;
    font-weight: 600;
}

@media (max-width: 786px){

    .filter-btn-float .btn-primary, .filter-btn-float .btn-primary:hover {
        color: #fff;
        background-color: e81d2a !important;
        border-color: e81d2a !important;
        font-size: 14px;
        font-weight: 400;
        padding: 12px;
        outline: none !important;
        box-shadow: none !important;
    }
    
    .filter-btn-float  {
        position: fixed;
        background: e81d2a;
        width: 100%;
        bottom: 0;
        left: 0;
        text-align: center;
        z-index: 99;
        border: none;
        border-radius:0px ;
    }
}


@media (min-width: 787px) {
    .modify-section-xs{
        display: none;
    }
    
    .filter-btn-float{
        display: none;
    }

    .filter-mob{
        display: block !important;
    }
}

/* new UI for ITWgo */

.light {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
       bottom: 295px;
    position: absolute;
    z-index: 10;
    left: 20px;
    animation: light 400ms ease-in-out 0s infinite alternate;
  }
  .cloud {
    position: absolute;
    top: -7px;
    right: -500px;
    opacity: .8;
    animation: cloud 30s linear 0s infinite reverse;
    z-index: 9;
  }

  .cloud img{
      width: 30px;
      transform: rotate(90deg);
  }
  @keyframes light {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @keyframes cloud {
    from {right:0px;}
    to {right: 88%;}
  }

  .progess-wrp{
      margin-top: 0%;
      background-color: #ddd;
  }
  .flight-progress-wrp .MuiLinearProgress-root {
        margin: 6px 0px !important;
        height: 2px !important;
        background-image: linear-gradient(90deg, transparent, transparent 50%, #ccc 50%, #ccc 100%), linear-gradient(90deg, #ddd, #ddd);
        background-size: 10px 2px, 100% 2px;
        border: none;

    }
   .flight-progress-wrp .MuiLinearProgress-bar{
       height: 2px !important;
       background-image: linear-gradient(90deg, transparent, transparent 50%, #fff 50%, #fff 100%), linear-gradient(90deg, #ddd, #ddd);
       background-size: 10px 2px, 100% 2px;
   }

    .dashed-line{
        display: block;
        border: 0.4px solid #ccc;
        background-color: #808080;
    }
    .share-cross-btn svg{
        font-size: 17px !important;
    }
    /* .reset-btn{
        text-align: right;
        border-bottom: 0.3px solid #444;
    } */

    .reset-btn {
        position: fixed;
        text-align: right;
        /* border-bottom: 0.3px solid #444; */
        background: #000;
        right: 25px;
        z-index: 99;
        margin-top: -15px;
        padding: 5px 0px;
    }
    .arrow-img-round{
        position: relative;
    }