.setBox input::placeholder{
    font-size: 13px !important;
}

.setBox input::placeholder{
    font-size: 13px !important;
}

.profileheader.container-fluid {
    background: #1675b8;
}

.MuiInputBase-input{
    padding: 10px;
}

.MuiInputBase-root{
    font-size: 14px !important;
}

.tltprofilename {
    color: #fff;
    font-size: 25px;
    padding: 15px;
    margin-left: 2%;
}

span.tltnamegray {
    font-size: 19px;
    color: #ccc;
}

span.tltnamegray {
    font-size: 21px;
    color: #ccc;
    font-weight: 500;
    letter-spacing: 1px;
}

.btnedit {
    padding: 15px;
    text-align: right;
    margin-right: 5%;
}

.img-ico {
    width: 80px;
    height: 80px;
    border: 1.5px solid #e81d2a;
    border-radius: 50%;
    padding: 13px;
    background: #e81d2a0a;
}
.img-ico svg{
    font-size: 3rem;
    text-align: center;
    color: #e81d2a;
}

.img-ico img {
    width: 100%;
    padding: 7px;
    border-radius: 50%;
    object-fit: cover;
    height: 100%;
}

.tltnameico {
    font-size: 20px;
    font-weight: 500;
    margin-left: 25px;
}

.userbox {
    width: 100%;
    display: flex;
    align-items: center;
}

.user-ico svg {
    font-size: 16px;
    color: #e81d2a;
}

.tlthedae {
    font-size: 13px;
    font-weight: 500;
    color: #444;
}

.tltclearboldname {
    font-size: 16px;
    font-weight: 500;
}

.user-details {
    margin-left: 5px;
}

.itemRow {
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 25px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 7px rgb(0 0 0 / 25%);
}

.setBox {
    margin-bottom: 11px;
}

.imguserprofile {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

button.btnEditProfile {
    font-size: 14px;
    font-weight: 500;
    padding: 6px 15px;
}

.para-txt {
    width: 65%;
    padding: 0px 10px;
    font-size: 12px;
    font-weight: 400;
    color: #808080;
}

.choosebtn {
    margin-left: 25px;
}

.tlteditname {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
}

.tlteditname span {
    color: red;
    padding: 5px;
}

.inputedit input {
    padding: 10px;
}

.inputedit {
    width: 100%;
}

.editinputfield {
    margin: 10px 0;
}

.editbtnfield {
    margin: 20px 0;
}

button.btnupdate {
    margin-right: 15px;
}

.inputBox>div {
    margin: 0px;
    width: 100%;
}

.tlt-change-password {
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
}

.changepasswordbox {
    display: flex;
    width: 100%;
    align-items: center;
}

.changepasswordtlt {
    font-size: 18px;
    font-weight: 600;
    flex-basis: 40%;
}

.changepasswordbox {
    margin-bottom: 25px;
}

.insideRow {
    padding: 0 20%;
}

button.btnsubmitchange {
    margin-right: 15px;
    min-width: 170px;
}

button.btncancelchange {
    min-width: 170px;
}

.btnSect {
    text-align: center;
    margin: 25px 0;
}

.mobiledropdowntxt {
    display: flex;
    align-items: center;
}

.inputBoxmobile input {
    padding: 9.8px;
    width: 80px;
}

.profilePhotoError {
    color: #f44336;
    margin: 0;
    font-size: 12px;
    margin-top: 3px;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}


.form-submit{
    padding: 13px 30px;
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 400;
  }
  .kb-data-box {
      width: 100%;
      flex: 1;
  }
  
  .kb-modal-data-title {
      margin-bottom: 10px;
  }
  .kb-data-title h6 {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 600;
  }
  .kb-file-upload {
      flex-basis: 80%;
  }
  .kb-buttons-box{
    flex-basis: 20%;
    margin-left: 20px;
  }
  .file-upload-box {
      border: 1px dashed #b6bed1;
      background-color: #f0f2f7;
      border-radius: 4px;
      min-height: 40px;
      position: relative;
      overflow: hidden;
      padding: 8px 15px;
      /* display: flex; */
      margin-right: 10px;
      align-items: center;
      justify-content: center;
      color: #8194aa;
      font-weight: 400;
      font-size: 15px;
  }
  .file-upload-box .file-upload-input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }
  .file-upload-box span{
    font-size: 12px;
  }
  .file-link{
      color: #475f7b;
      text-decoration: underline;
      margin-left: 3px;
  }
  .file-upload-box .file-link:hover{
      text-decoration: none;
  }
  .file-atc-box {
      position: relative;
      align-items: center;
      margin-bottom: 15px;
  }
  .file-image {
      /* width: 130px;
      min-width: 130px;
      height: 85px;
      min-height: 85px; */
      width: 260px;
      height: 170px;
      background-size: cover;
      border-radius: 5px;
      margin-right: 15px;
      background-color: #eaecf1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: #475f7b;
      padding: 3px;
  }
  .file-image img{
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
  }
  .file-detail {
      flex: 1;
      width: calc(100% - 210px);
  }
  .file-detail h6 {
      word-break: break-all;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 8px;
  }
  .file-detail p {
      font-size: 12px;
      color: #8194aa;
      line-height: initial;
      font-weight: 400;
      margin-bottom: 8px;
  }
  .file-actions {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      align-items: center;
  }
  .file-action-btn {
      font-size: 12px;
      color: #8194aa;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      text-decoration: underline;
      margin-right: 15px;
      cursor: pointer;
  }
  .file-action-btn:hover {
      color: #3d546f;
      text-decoration: underline;
  }
  .file-atc-box:last-child{
      margin-bottom: 0;
  }

  .setBox .MuiInputBase-input{
    padding: 10px
  }

  .setBox .MuiFormLabel-root{
    font-size: 10px;
  }

  .row-btn svg{
    font-size: 17px !important;
  }

  .row-btn button{
    min-width: 30px;
  }

  .setBox fieldset{
    border-color: #ddd !important;
  }
  .file-atc-box button{
    position: absolute;
    top: -13px;
    right: -12px;
    min-width: 20px;
  }

  .file-atc-box button svg{
    font-size: 20px;
    color: red;
  }